<template>
  <b-modal
    id="avatarModal"
    v-model="state"
    title="User Avatar"
    size="lg"
    :ok-disabled="!isCropperReady"
    :no-close-on-backdrop="true"
    @hide="onClose"
    @ok="onOk"
  >
    <div class="d-flex flex-row">
      <div class="w-50">
        <img
          v-if="state"
          ref="cropperRef"
          :src="src"
          style="display: block; width: 100%;"
          crossorigin="anonymous"
          @load="onImgLoad"
        >
      </div>
      <div class="w-50 d-flex flex-column align-items-center justify-content-center">
        <font-awesome-icon
          v-if="!isCropperReady"
          icon="spinner"
          :spin="true"
          size="4x"
        />
        <div
          class="img-preview preview-lg"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'

export default {
  name: 'ModalAvatar',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: ''
    },
    mediaId: {
      type: [String, Number, null],
      default: ''
    }
  },
  data () {
    return {
      state: this.value,
      isCropperReady: false,
      cropper: undefined
    }
  },
  watch: {
    value (n) {
      this.state = n
    }
  },
  mounted () {
  },
  methods: {
    onImgLoad () {
      const img = this.$refs.cropperRef
      this.cropper = new Cropper(img, {
        aspectRatio: 1 / 1,
        preview: '.img-preview',
        checkCrossOrigin: true,
        checkOrientation: true,
        zoomable: false,
        scalable: false,
        ready: () => {
          this.isCropperReady = true
        }
      })
    },
    onClose () {
      this.isCropperReady = false
      this.cropper.destroy()
      this.state = false
      this.$emit('input', false)
    },
    onOk () {
      if (this.isCropperReady) {
        this.isCropperReady = false
        this.cropper.getCroppedCanvas({
          width: 380,
          height: 380
        }).toBlob((blob) => {
          this.cropper.destroy()
          const data = {
            blob
          }
          if (this.mediaId) {
            data.mediaId = this.mediaId
          }
          this.$emit('on-store', data)
        })
      }
    }
  }
}
</script>

<style scoped>
  .img-preview {
    overflow: hidden;
  }
  .img-preview > img {
    max-width: 100%;
  }
  .preview-lg {
    height: 16rem;
    width: 16rem;
  }
</style>
